import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_ROUTE } from '../api/constant';
import PostCard from './PostCard';

const PostList = ({ userId, postId }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchPosts = async () => {
    if (loading || !hasMore) return; // Prevent multiple requests
    setLoading(true);
    try {
      const url = postId
        ? `${API_ROUTE}/api/post?postId=${postId}&page=${page}`
        : userId
          ? `${API_ROUTE}/api/post?userId=${userId}&page=${page}`
          : `${API_ROUTE}/api/post?page=${page}`;
      const response = await axios.get(url);
      // Check if the post already exists
      const updatedPosts = response.data.data.reduce((acc, newPost) => {
        const existingPostIndex = acc.findIndex(existingPost => existingPost.id === newPost.id);
        if (existingPostIndex >= 0)
          acc[existingPostIndex] = newPost;  // If it exists, replace the old post with the new one
        else
          acc.push(newPost);  // If it doesn't exist, add the new post
        return acc;
      }, [...posts]); // Start with existing posts
      // Update the state with the new posts
      setPosts(updatedPosts);
      setHasMore(response.data.data.length > 0); // Check if there are more posts
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (loading || !hasMore) return; // Prevent triggering if loading or no more posts
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100 && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    fetchPosts();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [userId, postId, page, hasMore]);

  return (
    <div className="post-list pt-0 p-4 lg:px-48 lg:pt-4 lg:pb-6">
      {posts.map((post, key) => (
        <PostCard
          key={key}
          postId={post.id}
          username={post.creator_name}
          title={post.title}
          description={post.description}
          image={post.image}
          created_at={post.created_at}
          like_count={post.total_likes}
          comment_count={post.total_comments}
          showUserDetails={!userId}
        />
      ))}
      {loading && <div className="loader text-center">Loading...</div>}
    </div>
  );
};

export default PostList;