import React, { useEffect, useState } from 'react';
import { CiSearch } from "react-icons/ci";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useLocation, useNavigate } from 'react-router-dom';
const Button = ({ children, isActive, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`px-7 py-3 md:px-8 text-xs md:text-sm lg:text-sm font-bold rounded-md shadow-md transition-colors duration-300 ${isActive ? 'bg-[#0566B1] text-[white]' : 'bg-[#D7ECFC] text-[#04518D]'
        } w-full md:w-auto`}
      style={{ boxShadow: '0px 5px 6px 0px rgba(0, 0, 0, 0.15)', }}
    >
      {children}
    </button>
  );
};
const Community = () => {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState('');
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (location.pathname.includes('allpost')) {
      setActiveButton('allpost');
    } else if (location.pathname.includes('mypost')) {
      setActiveButton('mypost');
    } else if (location.pathname.includes('createpost')) {
      setActiveButton('createpost');
    }
  }, [location.pathname]);
  return (
    <div>
      {/* Header */}
      <header
        className="bg-[#A7DAF7] p-6 md:p-14 lg:p-20 rounded-b-0 lg:rounded-b-[35px]"
        style={{
          background: "linear-gradient(180deg, rgba(167, 218, 247, 0.3) 0%, rgba(5, 102, 177, 0.3) 100%)",
        }}
      >
        <div className="flex flex-col md:flex-row justify-between">
          <div className="font">
            <div className="flex items-center text-[#04518D] cursor-pointer mb-2 md:mb-8 lg:mb-16 text-base font-medium" onClick={handleBackClick}>
              <IoIosArrowBack />
              <p className="m-0">Back</p>
            </div>
            <h1 className="text-3xl font-bold tracking-wide text-[#04518D] text-center md:text-6xl md:leading-normal md:text-left lg:text-8xl lg:text-left">
              Welcome to <span className="text-[#6EBEF7]">Our<br /> Community</span>
            </h1>
          </div>
          <div className="flex justify-center md:justify-end pt-10 md:pt-0 sm:max-w-xs lg:max-w-screen-sm">
            <img src={process.env.PUBLIC_URL + "/images/Group 590.png"} alt="" />
          </div>
        </div>
      </header>
      {/* Main Content */}
      <main className="flex flex-col md:flex-row justify-between items-center p-4 lg:px-48 lg:pt-14 lg:pb-6">
        <div className="flex gap-4 mb-4 md:mb-0 w-full md:w-1/2 justify-between md:justify-start">
          <Link to="/allpost"><Button isActive={activeButton === 'allpost'}
            onClick={() => setActiveButton('allpost')}>All posts</Button></Link>
          <Link to="/mypost"><Button isActive={activeButton === 'mypost'}
            onClick={() => setActiveButton('mypost')}>My posts</Button></Link>
          <Link to="/createpost"><Button isActive={activeButton === 'createpost'}
            onClick={() => setActiveButton('createpost')}>Create a post</Button></Link>
        </div>
        {location.pathname !== '/createpost' && (
          <div className='mt-3 md:mt-0 w-full md:w-1/2 flex flex-row-reverse'>
          <div className="relative w-full md:w-[370px] p-2 border border-black h-[56px] rounded-md">
            <input
              type="text"
              placeholder="Search"
              className="w-full h-full px-3 pr-10 border-none rounded-md"
              style={{ fontSize: '16px' }}
            />
            <CiSearch className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600 w-[23px] h-[23px]" />
          </div>
          </div>
        )}
      </main>
    </div>
  );
};
export default Community;
