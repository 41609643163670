import React from 'react'
import Community from './Community'
import PostList from './PostList'

const AllPost = () => {


  return (
    <div>
      <Community/>
      <PostList/>
    </div>
  )
}

export default AllPost;
